import React, { useState, useEffect } from "react";
import parse from "html-react-parser";
import { get } from "http";

const Blog = ({ bloggerId, bloggerKey, postToShow }) => {
  const [blog, setBlog] = useState(null);
  const [items, setItems] = useState([]);

  const getText = (html) => {
    var divContainer = document.createElement("div");
    divContainer.innerHTML = html;
    return divContainer.textContent || divContainer.innerText || "";
  };
  const formatResume = (text) => {
    const length = text.length;
    const nLength = 200;
    if (length > nLength) {
      text = text.substring(0, nLength);
      text = text.substring(0, text.lastIndexOf(" ")) + "...";
    }
    return text;
  };

  const url = `https://www.googleapis.com/blogger/v3/blogs/${bloggerId}/posts?key=${bloggerKey}&maxResults=${postToShow}&orderBy=published`;
  const getBlogList = () => {
    fetch(url)
      .then((res) => res.json())
      .then((json) => {
        setBlog({ data: json });
      });
  };
  useEffect(() => {
    if (blog) {
      if (!items.length) {
        let nItems = blog.data.items;
        const parser = new DOMParser();
        var its = [];
        nItems.forEach((n) => {
          const html = parser.parseFromString(n.content, "text/html");
          const img = html.querySelector("img");
          const url = img ? img.src : "";
          if (url) {
            n.image = url;
          } else {
            n.image = "https://via.placeholder.com/550";
          }
          n.contentText = formatResume(getText(n.content).trim());
          its.push(n);
        });

        setItems(its);
      }
    }
  }, [blog, items.length]);

  if (!blog) getBlogList();

  return (
    <div className="post-list">
      {items &&
        items.map((i) => (
          <div className="post-item" key={i.etag}>
            <div
              className="post-img"
              style={{
                backgroundImage: `url(${i.image})`,
              }}
            ></div>
            <h2>
              <a href={i.url} target="_blank" alt="" rel="noreferrer">
                {i.title}
              </a>
            </h2>
            <div className="post-resumen">{i.contentText}</div>
          </div>
        ))}
    </div>
  );
};

export default Blog;
