import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

export const ContactForm = ({ fromEmail }) => {
  const [sendingContact, setSendingContact] = useState(false);
  const [sendedContact, setSendedContact] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  useEffect(() => {
    let btn = document.getElementById("btnSendContact");
    if (sendingContact) {
      btn.innerText = "Enviando...";
      btn.classList.add("disabled-button");
    } else {
      btn.innerText = "Enviar";
      btn.classList.remove("disabled-button");
    }
  }, [sendingContact]);

  const sender = async (data) => {
    console.log("data", "\n", data);
    try {
      debugger;
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
      var urlencoded = new URLSearchParams();
      urlencoded.append("email", data.txtEmail.trim().toLowerCase());
      urlencoded.append("name", data.txtNombre.trim());
      urlencoded.append("celular", data.txtCelular);
      urlencoded.append("message", data.txtAsunto.trim());
      urlencoded.append("from", fromEmail.trim().toLowerCase());
    } catch (error) {
      console.log(error);
    }
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    fetch(
      "http://paulaporras.com/mailer/index.php?action=email",
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        console.log(result);
      })
      .catch((error) => console.log("error", error));
  };

  const onSubmitContact = async (data) => {
    setSendingContact(true);
    await sender(data);

    setTimeout(() => {
      setSendingContact(false);
      setSendedContact(true);
      reset();
    }, 4000);
  };
  return (
    <>
      <form onSubmit={handleSubmit(onSubmitContact)} noValidate={true}>
        <div className="container-input">
          <input
            className="form-contact-input"
            type="text"
            placeholder="NOMBRE"
            autoComplete="chrome-off"
            {...register("txtNombre", { required: true })}
          />
          {errors.txtNombre && (
            <span className="field-error">Este campo es requerido...</span>
          )}
        </div>
        <div className="container-input">
          <input
            className="form-contact-input"
            type="email"
            placeholder="EMAIL"
            autoComplete="chrome-off"
            {...register("txtEmail", {
              required: true,
              minLength: 4,
              pattern: /^\S+@\S+$/i,
            })}
          />
          {errors.txtEmail && (
            <span className="field-error">Este campo es requerido...</span>
          )}
        </div>
        <div className="container-input">
          <input
            className="form-contact-input"
            type="tel"
            placeholder="CELULAR"
            autoComplete="chrome-off"
            {...register("txtCelular", {
              required: true,
              minLength: 7,
              pattern: /^[0-9]*$/,
            })}
          />
          {errors.txtCelular && (
            <span className="field-error">
              Este campo es requerido y debe ser un número telefónico
            </span>
          )}
        </div>
        <div className="container-input">
          <textarea
            className="form-contact-textarea"
            type="text"
            placeholder="ASUNTO"
            autoComplete="chrome-off"
            {...register("txtAsunto", { required: true })}
          ></textarea>
          {errors.txtAsunto && (
            <span className="field-error">Este campo es requerido...</span>
          )}
        </div>
        <div className="container-button">
          <button id="btnSendContact" className="form-contact-button">
            Enviar
          </button>
        </div>
        {sendedContact && (
          <div className="container-result">
            ¡Gracias, tu mensaje es muy importante para nosotros, pronto nuestro
            equipo se pondrá en contacto contigo!
          </div>
        )}
      </form>
    </>
  );
};
