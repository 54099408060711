import React, { useEffect, useState } from "react";
import Blog from "../componentes/Blog";
import { ContactForm } from "./forms/Contacto";
import data from "./../data.json";
import parse from "html-react-parser";

const HomePage = () => {
  const [offset, setOffset] = useState(0);
  const [menuActive, setMenuActive] = useState("#home");

  useEffect(() => {
    window.onscroll = () => {
      setOffset(window.pageYOffset);
    };
  }, []);

  return (
    <>
      {offset > 100 && (
        <div className="floating-to-top">
          <a href="#home" rel="noreferrer" alt="">
            <svg viewBox="0 0 24 24">
              <path
                fill="white"
                d="M7.41,18.41L6,17L12,11L18,17L16.59,18.41L12,13.83L7.41,18.41M7.41,12.41L6,11L12,5L18,11L16.59,12.41L12,7.83L7.41,12.41Z"
              />
            </svg>
          </a>
        </div>
      )}
      <div className="menu-ico-mobile">
        <svg viewBox="0 0 24 24">
          <path
            fill="currentColor"
            d="M3,6H21V8H3V6M3,11H21V13H3V11M3,16H21V18H3V16Z"
          />
        </svg>
      </div>

      <div className="container-nav-bar">
        <div
          className={"nav-bar-item " + (menuActive === "#home" ? "active" : "")}
        >
          <a
            href="#home"
            rel="noreferrer"
            onClick={() => setMenuActive("#home")}
          >
            HOME
          </a>
        </div>
        <div
          className={"nav-bar-item " + (menuActive === "#bio" ? "active" : "")}
        >
          <a href="#bio" rel="noreferrer" onClick={() => setMenuActive("#bio")}>
            BIO
          </a>
        </div>
        <div
          className={
            "nav-bar-item " + (menuActive === "#servicios" ? "active" : "")
          }
        >
          <a
            href="#servicios"
            rel="noreferrer"
            onClick={() => setMenuActive("#servicios")}
          >
            SERVICIOS
          </a>
        </div>
        <div
          className={"nav-bar-item " + (menuActive === "#blog" ? "active" : "")}
        >
          <a
            href="#blog"
            rel="noreferrer"
            onClick={() => setMenuActive("#blog")}
          >
            BLOG
          </a>
        </div>
        <div
          className={
            "nav-bar-item " + (menuActive === "#contacto" ? "active" : "")
          }
        >
          <a
            href="#contacto"
            rel="noreferrer"
            onClick={() => setMenuActive("#contacto")}
          >
            CONTACTO
          </a>
        </div>
      </div>

      <div className="home-logo">
        <img src={process.env.PUBLIC_URL + "/assets/logo.png"} alt="" />
      </div>
      <div
        id="home"
        className="container-home-photo"
        style={{
          backgroundImage: `url(${
            process.env.PUBLIC_URL + "/assets/home.png"
          })`,
        }}
      >
        <div className="whatsapp-callme">
          <a
            href={`https://api.whatsapp.com/send?phone=${process.env.REACT_APP_WHATSAPP_NUMBER}`}
            rel="noreferrer"
            target="_blank"
          >
            <img src={process.env.PUBLIC_URL + "/assets/whats.png"} alt="" />
          </a>
        </div>
      </div>

      <div id="bio" className="container-panel">
        <div
          className="photo-panel-dos"
          style={{
            backgroundImage: `url(${
              process.env.PUBLIC_URL + "/assets/img01.png"
            })`,
          }}
        ></div>

        <div className="texto-panel-dos">
          <h1>{parse(data.bio.title)}</h1>
          {parse(data.bio.text)}
        </div>
      </div>

      <div id="servicios" className="container-panel-tres">
        <div className="panel-tres-row top-content">
          <div className="panel-tres-content">
            <h1>{parse(data.services.title)}</h1>
            <span>{parse(data.services.text)}</span>
          </div>
        </div>
        <div className="panel-tres-row bottom-content">
          <div className="circle-container">
            {data.services.items.map((x) => (
              <div key={x.id} className="circle-item">
                <div className="circle-shape"></div>
                <h2>{parse(x.title)}</h2>
                <span className="circle-texto">{parse(x.text)}</span>
                <a
                  href={`https://api.whatsapp.com/send?phone=${data.whatsapp.number}`}
                  target="_blank"
                  rel="noreferrer"
                  className="agendar-cita"
                >
                  Agendar cita
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div id="blog" className="blog-container">
        <Blog
          bloggerId={data.blog.bloggerId}
          bloggerKey={data.blog.bloggerKey}
          postToShow={data.blog.bloggerPostToShow}
        />
      </div>

      <div id="contacto" className="footer-container">
        <div className="footer-item map-pointer">
          <svg className="map-marker" viewBox="0 0 24 24">
            <path d="M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z" />
          </svg>
          <div className="footer-address">
            {parse(data.contact.address.siteName)}
            <br />
            {parse(data.contact.address.addressLine)}
            <br />
            {parse(data.contact.address.additional)}
            <br />
            {parse(data.contact.address.cityCountry)}
          </div>
        </div>
        <div className="footer-item footer-form">
          <div className="form-div">
            <h1>{parse(data.contact.title)}</h1>
            <div className="hablemos-texto">{parse(data.contact.text)}</div>
            <div className="footer-social">
              <div>
                {data.contact.socialLinks.map((x) => (
                  <a
                    key={x.id}
                    href={x.link}
                    target="_blank"
                    rel="noreferrer"
                    alt=""
                  >
                    {parse(x.svg)}
                  </a>
                ))}
              </div>
              <div>{parse(data.contact.socialNickname)}</div>
              <a href={`mailto:${data.contact.email}`}>
                {parse(data.contact.email)}
              </a>
              <a
                href={`https://api.whatsapp.com/send?phone=${data.contact.phone.replaceAll(
                  " ",
                  ""
                )}`}
                rel="noreferrer"
                target="_blank"
              >
                {parse(data.contact.phone)}
              </a>
            </div>
          </div>
          <div className="form-div form-contact">
            <ContactForm fromEmail={data.contact.form.from} />
          </div>
        </div>
        <div className="footer">
          Copyright &copy; 2021 | PAULA PORRAS | Todos los derechos reservados.
        </div>
      </div>
    </>
  );
};

export default HomePage;
